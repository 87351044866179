@import '../../../App.scss';

.footer-top-wrapper {
	min-height: 400px;
	width: 100%;
	background-color: $blue-bg;

	.footer-top-container {
		padding: 30px 100px;
		display: flex;
		flex-direction: row;
		align-items: start;
		justify-content: center;
		gap: 30px;

		@include respond(phone) {
			padding: 30px 10px;
			display: block;
			text-align: center;
		}

		h3 {
			color: $white;
			font-size: 16px;
			font-weight: $regular-weight;
			width: 160px;

			@include respond(phone) {
				font-size: 14px;
			}
		}

		p {
			font-size: 14px;
			font-weight: $regular-weight;
			color: $grey-medium;

			@include respond(phone) {
				font-size: 10px;
			}
		}

		h2 {
			color: $white;
			font-size: 20px;
			font-weight: $bold-weight;
			text-transform: uppercase;
			margin-bottom: 40px;

			@include respond(phone) {
				font-size: 16px;
				margin-bottom: 20px;
			}
		}

		li {
			margin-bottom: 10px;

			@include respond(phone) {
				margin-bottom: 5px;
			}

			h3:hover {
				color: $yellow-base;
			}
		}

		.footer-logo-container {
			flex-basis: 60%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.footer-logo-top-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.footer-logo img {
					width: 250px;
					height: auto;

					@include respond(phone) {
						width: 150px;
					}
				}

				.footer-socials {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 50px;
					margin: 30px auto;
				}

				&:after {
					margin-bottom: 20px;
					content: ' ';
					display: block;
					width: 180%;
					border: 1px solid $grey-base;

					@include respond(tab-port-sm) {
						width: 120%;
					}

					@include respond(phone) {
						width: 130%;
						margin-bottom: 0;
					}
				}
			}

			.footer-unidade-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 40px;

				@include respond(phone) {
					// display: grid;
					// // grid-template-rows: 100px 100px 100px;
					// // grid-template-columns: 150px 150px;
					// grid-template-rows: 100px;
					// grid-template-columns: 150px;
					// grid-row-gap: 0px;
					// grid-column-gap: 30px;
					margin: 20px 0;
				}

				.footer-unidade-row {
					display: flex;
					gap: 10px;
					justify-content: space-between;
					align-items: flex-start;

					@include respond(phone) {
						display: grid;
						// grid-template-rows: 100px;
						// grid-template-columns: 150px;
						grid-row-gap: 10px;
						grid-column-gap: 30px;
						margin: 20px 0 px;
					}
				}

				.footer-unidade {
					width: 160px;

					h3 {
						margin-bottom: 10px;

						@include respond(phone) {
							margin-bottom: 3px;
						}
					}
				}
			}
		}

		.links-container {
			flex-basis: 40%;

			ul {
				@include respond(phone) {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					gap: 20px;
					margin: 20px 0;
					margin-bottom: 50px;
				}
			}

			.segurança-container {
				display: flex;
				align-items: center;
				justify-content: left;
				gap: 10px;
				margin-top: 2rem;

				@include respond(phone) {
					justify-content: center;
					gap: 0px;

					h3 {
						font-size: 13px;
					}

					img {
						width: 36px;
					}
				}
			}

			.segurança-text {
				width: 160px;
			}

			.segurança-text h3 {
				color: $green-base;
				font-weight: $semibold-weight;
			}
		}

		.info-container {
			flex-basis: 20%;

			ul {
				display: flex;
				flex-direction: column;
				gap: 20px;

				@include respond(phone) {
					align-items: center;
					justify-content: center;
					gap: 10px;
				}
			}

			.info-container li {
				margin-bottom: 30px;
			}

			.endereço-container,
			.telefone-container {
				display: flex;
				align-items: center;
				justify-content: left;
				gap: 10px;

				@include respond(phone) {
					gap: 0px;

					h3 {
						font-size: 13px;
					}

					img {
						width: 36px;
					}
				}
			}
		}
	}
}