// GLOBAL SCSS
@import '../../App.scss';

// HEAD
.head {
	background-color: $blue-base;
	padding: 8px 80px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	.head-left-text,
	.head-right-text {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 50px;

		.phone,
		.email,
		.perguntas,
		.termos {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;

			&:hover {

				label,
				i {
					color: $yellow-base;
				}
			}

			span {
				color: $grey-lighter;
				font-size: 18px;
				cursor: pointer;
			}

			.atendimento, a {
				color: $grey-lighter;
				font-weight: $semibold-weight;
				font-size: 14px;
				cursor: pointer;

				&:hover {
					color: $yellow-base;
				}

				@include respond(tab-port){
					font-size: 12px
				}
			}

			label,
			.link {
				color: $grey-lighter;
				font-weight: $semibold-weight;
				font-size: 14px;
				cursor: pointer;
			}

			.link {
				&:hover {
					color: $yellow-base;
				}
			}
		}
	}
}

// HEADER
.header {
	background-color: $white;
	position: sticky;
	top: 0;
	z-index: 9999;
	padding: 15px 150px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.header-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 35px;

		@media screen and (max-width: 1030px) {
			gap: 5px;
		}

		@media screen and (max-width: 700px) {
			gap: 2px;
		}
	}

	.logo-container {
		img {
			min-width: 85px;
			width: 280px;
			height: auto;
			cursor: pointer;
		}
	}

	.categoria-btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto 20px;

		/* The dropdown container */
		.dropdown {
			float: left;
			overflow: hidden;
			border-radius: 5px;

			.categoria-btn {
				padding: 8px 5px;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.2s;

				// background-color: $grey-lighter;
				&:hover {
					background-color: $grey-lighter;
				}

				span {
					margin-left: 5px;
					font-size: 28px;
					color: $green-medium;
				}

				p {
					margin-left: 10px;
					font-weight: $semibold-weight;
					color: $blue-bg;
					font-size: 18px;
				}

				#yellow-arrow {
					color: $yellow-base;
				}
			}

			.dropdown-content {
				display: none;
				position: absolute;
				background-color: $white;
				min-width: 300px;
				box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
				z-index: 1;

				a {
					float: none;
					color: $grey-brand;
					padding: 12px 16px;
					margin: 0;
					font-size: 14px;
					font-weight: $semibold-weight;
					text-decoration: none;
					display: block;
					text-align: left;

					&:hover {
						background-color: $blue-brand;
						color: $yellow-base;
					}
				}

				.filtro-nav-container {
					margin-top: 5px;
					display: flex;
					flex-direction: row;
					align-items: center;
					width: 100%;
					height: 35px;
					background-color: $white;
					// border-radius: 6px;
					transition: 0.2s;
					cursor: pointer;
					overflow: hidden;
					transition: transform 0.25s;

					&:hover {
						transform: translateY(-3px);
						background-color: $blue-brand;

						.filtro-nav-icon span {
							color: $yellow-base;
						}

						.filtro-nav-text {
							color: $white;
						}
					}

					.filtro-nav-icon {
						// margin-left: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						width: 20%;

						span {
							color: $blue-base;
							font-size: 28px;
						}
					}

					.filtro-nav-text {
						// margin-left: 10px;
						font-size: 14px;
						color: $grey-brand;
						font-weight: $regular-weight;
					}
				}
			}

			&:hover {
				.dropdown-content {
					display: block;
				}
			}
		}
	}

	.search-container {
		flex: 40%;
	}

	.account-cart-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 1.0rem;
		margin-left: 25px;
		position: relative;

		.account-btn {
			width: 42px;
			height: 42px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.2s;

			img {
				width: 42px;
				height: 42px;
				border-radius: 50%;
				object-fit: cover;
				border: 2px solid $yellow-base;
				box-shadow: $box-shadow-sm;
				transition: all 0.2s;
			}

			span {
				padding: 3px;
				font-size: 36px;
				color: $white;
				text-shadow: $txt-shadow;
				background-color: $blue-light;
				border-radius: 50%;
				transition: 0.2s;

				&:hover {
					background-color: $blue-medium;
				}
			}

			&:hover {
				transform: translateY(-2px);
			}
		}

		.account-logged-out {
			@extend .account-btn;

			span {
				padding: 3px;
				font-size: 36px;
				color: $blue-light;
				text-shadow: $txt-shadow;
				background-color: $grey-lighter;
				border-radius: 50%;
				transition: 0.2s;

				&:hover {
					background-color: $grey-light;
				}
			}
		}

		.compra-rapida-header {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background-color: $grey-lighter;
			background-size: 200% auto;
			transition: 0.5s;

			span {
				font-size: 30px;
				padding: 8px;
				color: #10244cb5;
				text-shadow: $txt-shadow;
				border-radius: 50%;
				transition: 0.2s;

				&:hover {
					background-color: $grey-light;
				}
			}

			&:hover {
				transform: translateY(-2px);
			}
		}

		.account-dropdown {
			// overflow: hidden;
			width: 200px;
			position: absolute;
			top: 65px;
			right: 60px;
			background-color: $white;
			// border-radius: 5px;
			box-shadow: $box-shadow;

			&:before {
				position: absolute;
				top: -14px;
				left: 120px;
				border-top: 15px solid $white;
				border-left: 15px solid rgba(0, 0, 0, 0);
				border-right: 15px solid rgba(0, 0, 0, 0);
				content: '';
				display: inline-block;
				height: 0;
				vertical-align: top;
				width: 0;
				transform: rotate(180deg);
				filter: drop-shadow(1px 4px 2px rgba(12, 36, 97, 0.1));
			}

			.account-menu {
				text-align: left;
				display: flex;
				flex-direction: column;
				font-size: 14px;
				inline-size: 200px;
				overflow-wrap: break-word;

				.conta {
					text-transform: capitalize;
					padding: 10px;
					padding-bottom: 0;
					font-weight: $semibold-weight;
					font-size: 18px;
				}

				.email {
					padding: 10px;
					padding-top: 0;
					font-weight: $semibold-weight;
					color: $blue-brand;
				}

				.account-menu-item {
					padding: 10px;
					cursor: pointer;
					display: flex;
					align-items: center;

					&:hover {
						background-color: $blue-brand;
						color: $white;

						span {
							color: $white;
						}
					}

					span {
						margin-right: 5px;
						font-size: 22px;
						text-shadow: $txt-shadow;
					}

					#minha-conta-icon {
						color: $blue-medium;
					}

					#compra-rapida-icon {
						color: $green-medium;
					}

					#atendimento-icon {
						color: $yellow-base;
					}

					#sair-icon {
						color: $grey-medium;
					}
				}

				.sair {
					font-weight: $semibold-weight;

					&:hover {
						background-color: $grey-dark;
					}
				}

				.account-menu-hr {
					margin: 0;
					border: 1px solid $grey-light;
				}
			}
		}
	}
}

.box-shadow {
	@extend .header;
	box-shadow: $box-shadow-nav;
}

// NAVMENU
.navmenu {
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	flex-direction: row;
	margin: 0px 300px;
	top: 0;
	z-index: 9998;
	padding: 0 0 10px 0;

	@media screen and (max-width: 800px) {
		margin: 0px 2rem;
	}

	.compra-rapida-btn {
		padding: 8px 10px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
		background-size: 200% auto;
		background-image: linear-gradient(to right,
				$blue-medium 0%,
				$green-light 50%,
				$blue-medium 100%);
		transition: 0.5s;

		span {
			font-size: 28px;
			color: $yellow-base;
			text-shadow: $txt-shadow;
		}

		p {
			margin-left: 10px;
			font-weight: $semibold-weight;
			color: $white;
			font-size: 18px;
			text-shadow: $txt-shadow;
		}

		&:hover {
			background-position: right center;
		}
	}

	a {
		color: $grey-brand;
		font-size: 16px;
		font-weight: $semibold-weight;
		transition: transform 0.2s;

		&:hover {
			color: $yellow-base;
			transform: translateY(-2px);
		}

		&:active {
			color: $yellow-base;
		}
	}

	.separator {
		color: $blue-base;
		background-color: $blue-base;
		border-radius: 50%;
		font-size: 10px;
		opacity: 15%;
	}
}